<!--
--------------------------------------------------------------------------------
<copyright file="BendingTopParts.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.bend_top_xaxis_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.tooltip_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.bend_top_yaxis_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.bend_top_bars_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class BendingTopPartsInfo extends Vue {}
</script>
